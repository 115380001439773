@import "//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css";
:root {
  --yellow: gold;
  --bright-yellow: #ffed85;
}

fieldset, label {
  margin: 0;
  padding: 0;
}

body {
  margin: 20px;
}

h1 {
  margin: 10px;
  font-size: 1.5em;
}

.rating {
  float: left;
  border: none;
}

.rating > input {
  display: none;
}

.rating > label:before {
  content: "";
  margin: 5px;
  font-family: FontAwesome;
  font-size: 1.25em;
  display: inline-block;
}

.rating > .half:before {
  content: "";
  position: absolute;
}

.rating > label {
  color: #ddd;
  float: right;
}

.rating > input:checked ~ label, .rating:not(:checked) > label:hover, .rating:not(:checked) > label:hover ~ label {
  color: var(--yellow);
}

.rating > input:checked + label:hover, .rating > input:checked ~ label:hover, .rating > label:hover ~ input:checked ~ label, .rating > input:checked ~ label:hover ~ label {
  color: var(--bright-yellow);
}
/*# sourceMappingURL=index.f315dae4.css.map */
